<template>
  <div class="taskIndexView">
    <van-sticky>
      <van-nav-bar :title="$t('任务清单')" left-arrow @click-left="onClickLeft" />
    </van-sticky>
    <van-tabs
      v-model="active"
      @change="onChange"
      line-width="60px"
      background="#151d31"
      title-inactive-color="#ffffff"
      title-active-color="#4087f1"
    >
      <van-tab :title="$t('进行中')" name="0"></van-tab>
      <van-tab :title="$t('审核中')" name="2"></van-tab>
      <van-tab :title="$t('已完成')" name="1"></van-tab>
      <van-tab :title="$t('已失败')" name="-1"></van-tab>
      <van-tab :title="$t('恶意')" name="-3"></van-tab>
      <van-tab :title="$t('已放弃')" name="-2"></van-tab>
    </van-tabs>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('没有更多了')"
      loading-text="..."
      @load="onLoad"
    >
      <van-cell v-for="(item, key) in list" :key="item.id">
        <div class="xiax_item">
          <div class="left" @click="onShow(item)">
            <a href="javascript:;">
              <img :src="$imageUrl + item.thumb" />
            </a>
          </div>
          <div class="center" @click="onShow(item)">
            <span class="task">{{ item.type_name }}</span>
            <span class="task">{{ $t('任务要求') }}: {{ $t('观看视频') }}</span>
            <span class="time">{{ $t('创建') }}: {{ item.create_time }}</span>
            <span class="time">{{ $t('审核') }}: {{ item.shenhe_time }}</span>
          </div>
          <div class="right">
            <span class="price">
              {{ $t('单价') }}: 円{{ item.price }}
            </span>
          </div>
          <div class="state" v-if="active != 0">
             <img :src="dynamicImage" v-if="active != 0" />
          </div>  
        </div>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { formatDate } from "@/utils/func";
import { getOrderLists } from "@/api/task";
export default {
  data() {
    return {
      list: [],
      page: 1,
      pageSize: 15,
      loading: false,
      finished: false,
      showContentStatus: [],
      active: 0,
      fileList: [],
      status: 0,
      image: []
    };
  },
  activated() {
    this.status = 0;
    this.list = [];
    this.page = 1;
    this.finished = false;
  },
  created(){
    this.active = this.$route.params.id
      console.log(this.active)
  },
  computed: {
    dynamicImage() {

      if (this.active != 0) {
        return   require(`@/assets/images/state${this.active}-${this.$lang}.png`)
      }
      return ''; // 返回空字符串以避免错误

    },
  },
  methods: {

    //查看任务
    onShow(item) {
      this.$router.push({ name: "TaskShow", params: { id: item.id,task_id:item.task_id } });
    },
    onChange(value) {
      this.status = value;
      this.list = [];
      this.page = 1;
      this.finished = false;
    },
    onClickLeft() {
      this.$router.push("/main/user");
    },
    //日志列表
    GetOrderLists() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      getOrderLists({
        page: this.page,
        pageSize: this.pageSize,
        status: this.active
      })
        .then(response => {
          this.list.push.apply(this.list, response.result);
          this.list.forEach((item, index) => {
            this.list[index].showContentStatus = false;
          });
          // 加载状态结束
          this.loading = false;
          this.page++;
          if (response.result.length == 0) {
            this.finished = true;
          }
        })
        .catch(error => {});
    },
    onLoad() {
      this.GetOrderLists();
    }
  }
};
</script>
<style lang="stylus">
.taskIndexView
  .van-tabs__line
    background-color: #4087f1
  .van-tabs__wrap--scrollable .van-tab
    padding: 0 0.23rem
  .van-list
    margin-top: 0.2rem
    display: flex;
    flex-wrap: wrap;
    .van-cell
      background-color: #151d31
      margin-bottom: 0.1rem
      flex-basis: 50%;
      margin-right: 0.02rem;
      flex-basis: calc(50% - 0.02rem);
      .xiax_item
        display: flex
        flex-direction: column;
        position: relative;
        span
          display: block
        .state 
          position: absolute;
          bottom: 0;
          right: 0;
          img
            width: 2rem 
        .left
          margin-right: 0.2rem
          a
            background-color: #0e1526
            margin-right: 0.3rem
            width: 100%;
            img
              width: 100%
              height: 100%
        .center
          color: #758ab0
          font-size: 0.32rem
          line-height: 0.44rem;
          .task
            color: #ffffff
            font-size: 0.32rem
            font-weight: 500
          .time
            font-weight: 500
        .right
          text-align: left
          line-height: 0.44rem;
          .price
            color: #758ab0
            font-size: 0.32rem
            p
              color: #4087f1
              font-size: 0.32rem
              font-weight: 500
          .van-uploader
            display: block
            .van-uploader__wrapper
              justify-content: flex-end
            .van-uploader__upload
              background-color: #0b273f
              .van-uploader__upload-icon
                opacity: 0.3
                font-size: 0.5rem
                color: #dcdee0
          .van-button
            margin-right: 0.15rem
            padding: 0 0.3rem
            border-radius: 0.35rem
            height: 0.7rem
</style>
